<template>
  <div class="m-category-section">
    <div :id="productOffersCategoriesWidgetId" />
    <OSection
      class="m-category-section__section"
      :title-heading="$t(`VARUS special promotions`)"
      v-if="offersCategoriesProducts.length"
      is-centered
      is-not-mobile-padding
    >
      <MProductCarousel
        list-id="special_promotions"
        list-name="VARUS special promotions"
        :products="offersCategoriesProducts"
        arrow-color="gray"
        :slider-settings="sliderSettings"
        :key="offersCategoriesProducts.length"
      />
    </OSection>
    <!-- TODO - temporarily hide functionality Novelty -->
    <!-- <OSection
      class="m-category-section__section"
      :title-heading="$t(`Novelties`)"
      v-if="noveltiesProducts.length"
      is-centered
      is-not-mobile-padding
    >
      <MProductCarousel
        :products="noveltiesProducts"
        arrow-color="gray"
        :slider-settings="sliderSettings"
        :key="noveltiesProducts.length"
      />
    </OSection> -->
  </div>
</template>
<script>
import config from 'config';
import { mapGetters, mapState } from 'vuex';
import OSection from 'theme/components/organisms/o-section';
import MProductCarousel from 'theme/components/molecules/m-product-carousel';
import {
  BREAKPOINT_MD,
  BREAKPOINT_SM
} from 'theme/helpers/breakpoints';
import { loadRelatedProducts } from 'theme/helpers';

export default {
  name: 'MCategorySection',
  components: {
    OSection,
    MProductCarousel
  },
  data () {
    const breakpoints = {
      MD: {
        width: BREAKPOINT_MD,
        items: 4
      },
      SM: {
        width: BREAKPOINT_SM,
        items: 3
      },
      XSM: {
        width: BREAKPOINT_SM - 1,
        items: 2
      }
    }

    return {
      breakpoints,
      sliderSettings: {
        type: 'slider',
        perView: 4,
        keyboard: true,
        dragThreshold: false,
        gap: 10,
        breakpoints: {
          [breakpoints.MD.width]: {
            perView: breakpoints.MD.items
          },
          [breakpoints.SM.width]: {
            perView: breakpoints.SM.items,
            gap: 5,
            peek: {
              before: 0,
              after: 90
            }
          },
          [breakpoints.XSM.width]: {
            perView: breakpoints.XSM.items,
            swipeThreshold: false,
            gap: 5,
            peek: {
              before: 0,
              after: 62
            }
          }
        }
      },
      noveltiesProducts: [],
      categoryRelatedProducts: []
    }
  },
  watch: {
    // TODO - temporarily hide functionality Novelty
    // getBrandsNovelty: {
    //   handler (newValue) {
    //     this.noveltiesProducts = newValue;
    //   },
    //   immedate: true
    // }
  },
  computed: {
    ...mapState({
      getSampleProductsCategory: state => state['brand'].sampleProductsCategory
    }),
    ...mapGetters({
      getCurrentCategory: 'category-next/getCurrentCategory',
      getBrandsNovelty: 'brands/getBrandsNovelty'
    }),
    productOffersCategoriesWidgetId () {
      return config.esputnik?.widgetIds?.productOffersCategories;
    },
    offersCategoriesProducts () {
      return this.categoryRelatedProducts.length ? this.categoryRelatedProducts : this.getSampleProductsCategory;
    },
    popularProductsCategoryWidgetId () {
      return config.esputnik?.widgetIds?.popularProductsCategory;
    }
  },
  methods: {
    async getProductOffersCategories () {
      this.categoryRelatedProducts = await loadRelatedProducts(
        this.productOffersCategoriesWidgetId,
        config?.entities?.product?.carouselSize,
        true
      ) || []
    },
    async getPopularProductsCategoryPage () {
      const popularProductsCategory = await loadRelatedProducts(
        this.popularProductsCategoryWidgetId,
        config?.entities?.product?.queryMaxSize,
        true
      ) || []

      this.$store.dispatch('category-extension/loadRelatedChildCategories', {
        category: this.getCurrentCategory,
        relatedProducts: popularProductsCategory || []
      })
    }
  },
  // TODO - temporarily hide functionality Novelty
  // async created () {
  //   await this.$store.dispatch('brands/fetchBrandsNovelty')
  // },
  mounted () {
    window.addEventListener('productOffersCategoriesLoaded', this.getProductOffersCategories)
    window.addEventListener('popularProductsCategoryPageLoaded', this.getPopularProductsCategoryPage)
  },
  beforeDestroy () {
    window.removeEventListener('productOffersCategoriesLoaded', this.getProductOffersCategories)
    window.removeEventListener('popularProductsCategoryPageLoaded', this.getPopularProductsCategoryPage)
  }
}
</script>
<style lang="scss" scoped>
@import '~@storefront-ui/shared/styles/helpers/breakpoints';
@import '~theme/css/breakpoints';
@import "~theme/css/px2rem";

.m-category-section {
  padding-top: 0;

  ::v-deep {
    .o-section--center {
      padding: 0;
    }

    .sf-carousel__wrapper {
      padding-inline: 0;

      @media (min-width: $tablet-min) {
        padding-inline: var(--spacer-10);
      }
    }

    .glide__slide {
      .sf-product-card {
        padding-bottom: 0;

        &__image-wrapper {
          padding: var(--spacer-10);

          .sf-product-card__image {
            img {
              display: block!important;
            }
          }

          .sf-image {
            img {
              @include for-tablet {
                width: px2rem(217);
              }

              @include for-desktop {
                width: px2rem(210);
              }
            }
          }
        }
      }
    }
  }

  &__section {
    @include for-desktop {
      --max-width: 1024px;
    }

    ::v-deep {
      .sf-heading__title--h3 {
        font-size: var(--font-size-18);
        font-family: var(--font-family-inter);
        font-weight: var(--font-medium);
        text-transform: none;

        @include for-desktop {
          font-size: var(--font-size-24);
        }
      }
    }

    &:first-of-type {
      padding-top: 0;
      padding-bottom: var(--spacer-40);

      @include for-desktop {
        padding-bottom: var(--spacer-35);
      }
    }

    &:last-of-type {
      @include for-desktop {
        padding-top: 0;
        padding-bottom: var(--spacer-35);
      }
    }
  }
}
</style>
